
.updateThumbnailInput {
  padding-top: 1rem;
}

.updateThumbnailPreview {
  padding-top: 0.5rem;
  height: 100px;
}

.updateThumbnailButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
}