.adBannerCreate {
  padding-top: 2rem;
  /* border: 1px solid red; */
}

.adBannerCreateForm {
  padding: 0.5rem;
  border: 0.25px solid gray;
  border-radius: 4px;
}

.adBannerCreateTime {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.adBannerCreateTimeLabel {
  padding-bottom: 0.25rem;
}

.adBannerCreateModalButton {
  padding-top: 2rem;
}

.adBannerCreateMessage {
  padding-top: 1rem;
}

