.mainNav {
  position:fixed;
  top: 0; 
  width: 100%; 
  height: 3rem;
  /* background: var(--color-gray-100); */
  background-color: whitesmoke;
  box-shadow: var(--shadow-medium);
  display: flex;
  justify-content: space-between;
}


.mainNavLogo {
  padding: 0.75rem 0.25rem;
}

.mainNavLogoContent {
  padding-top: 1rem;
  color: gray;
  cursor: default;
}