

.adHostResultTableSelect {
  padding: 0.5rem;
  margin-bottom: 2rem;
  /* width: 95%; */
  width: 100%;
  /* max-width: 30rem; */
  font-size: 1.1rem;
}

.adHostResultTableSummary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.adHostResultTablePayOutAbout {
  text-align: right;
  padding-top: 1rem;
}

.adHostResultExportButton { 
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .adHostResultTableSummary {
    flex-direction: row;
  }
}