.mobileToggle {
  position: relative;
  background: transparent;
  border: none;
  display: flex;
  width: 2.5rem;
  height: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-top: 0.25rem;
}

.mobileToggle__bar {
  /* width: 2.5rem; */
  width: 2rem;
  height: 5px;
  /* height: 2.5px; */
  /* background: white; */
  /* background: red; */
  background: var(--color-gray-400);
  background: var(--color-gray-600);
}

.menuButton {
  /* display: none; */
  margin-left: 1rem;
  color: white;
  cursor: pointer;
}

.toggleNavButtonNotifyNum {
  position: absolute;
  top: 0px;
  left: -10px;
  padding: 0px 2px;
  border-radius: 50%;
  background: red;
  color: white;
}

@media (min-width: 768px) {
    .mobileToggle {
        /* display: none; */
        display: flex;
    }
}