.addPayOutAddressInput {
  padding-bottom: 1rem;
}

.addPayOutAddressCoinSelect {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addPayOutAddressButtons {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}