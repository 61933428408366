.payOutAddressListItem {
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 1.25rem;
  word-break: break-all;
}

.payOutAddressListItemContent {
  padding-bottom: 1rem;
}

.payOutAddressListItemCoin {
  display: flex;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.payOutAddressListItemCoinName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payOutAddressListItemDelete {
  padding-bottom: 1rem;
  text-align: right;
}

.payOutAddressListItemDeleteButtons {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}

.payOutAddressListItemEditButtons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}