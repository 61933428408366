.adHostDataMarkdown {
  font-size: 1rem;
}

.adHostDataCodeTitle {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.adHostDataCopyButton {
  cursor: pointer;
  padding-right: 1rem;
}

.adHostDataCopyButtonToolTip {
  /* cursor: pointer; */
  padding-right: 10px;
}