.payOutAbout {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  font-size: 1.25rem;
  padding: 1rem;
}

.payOutAboutTitle {
  font-size: 1.5rem;
}