.createAdHostTitle {
  padding-bottom:  1rem;
  font-size: 1.25rem;
}

.createAdHostInput {
  padding-bottom: 1rem;
}

.createAdHostAction {
  display: flex;
  padding-top: 1rem;
}

.createAdHostTooltipClose {
  text-align: right;
}

.createAdHostTooltipCloseButton {
  font-size: 1.5rem;
  cursor: pointer;
}
