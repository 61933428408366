.payOutAddressList {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.payOutAddressListAdd {
  padding-top: 2rem;
}

.payOutAddressListList {
  padding-top: 2rem;
}