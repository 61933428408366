.adhostListContainer {
  margin: 0 auto;
  padding: 0.5rem;
  max-width: 40rem;
}

.adhostListCreate {
  text-align: right;
  padding-top: 1rem;
  padding-bottom: 1rem;
}