.payOutResultList {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding-top: 2rem;
}

.payOutResultListTitle {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}
