.createAdHostTitle {
  padding-bottom:  1rem;
  font-size: 1.25rem;
}

.createAdHostInput {
  padding-bottom: 1rem;
}

.createAdHostFileInput {
  padding-bottom: 1rem;
}

.createAdHostPreviewVideo {
  max-height: 250px;
  max-width: 400px;
}

.createAdHostAction {
  display: flex;
  padding-top: 1rem;
}