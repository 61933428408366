.payOutResultListItem {
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 1.25rem;
  word-break: break-all;
}

.payOutResultListItemContent {
  padding-bottom: 1rem;
}

.payOutResultListItemAddress {
  font-size: 1rem;
}

.payOutResultListItemTxid {
  font-size: 0.9rem;
}