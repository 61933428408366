.userInfoContent {
  padding: 1rem;
}

.userInfoItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
}

.userInfoUserImage {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.userInfoUserNoImage {
  font-size: 50px;
}