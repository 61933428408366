.adhostListItemContainer {
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border: 0.25px solid gray;
  border-radius: 4px;
}

.adhostListItemElement {
  padding-bottom: 0.5rem;
}

.adhostListItemActions {
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
}