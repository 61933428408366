.aboutPageTextTitle {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.aboutPageTextList {
  padding-left: 1rem;
}

.aboutPageTextListItem {
  padding-bottom: 1rem;
}