.thumbnailDisplay {
  padding-top: 2rem;
}

.thumbnailDisplayTitle {
  font-size: 1.5rem;
}

.thumbnailDisplayImage {
  padding-top: 0.5rem;
  height: 75px;
}

.thumbnailDisplayButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
}

.thumbnailDisplayDefault {
  padding-top: 1rem;
}

.thumbnailDisplayDefaultImage {
  height: 50px;
  padding-top: 0.25rem;
}