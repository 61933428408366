.footer {
  height: 3rem;
  border-top: 0.5px solid gray;
}

.footerLinks {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}
.footerLink {
  padding: 0.5rem;
}