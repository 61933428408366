.pageNotification {
  width: 95%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem 0;
}

.pageNotificationTitle {
  position: relative;
}

.pageNotificationTitleNum {
  position: absolute;
  top: -5px;
  left: -10px;
  padding: 0px 2px;
  border-radius: 50%;
  background: red;
  color: white;
}

.pageNotificationList {
  list-style: none;
}