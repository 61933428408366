.videoControl {
  padding-top: 2rem;
}

.videoControlTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.videoControlItem {
  padding-top: 1rem;
}

.videoControlItemSize {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.videoControlItemSizeInput {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}