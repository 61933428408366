.videoWithAdBannerItem {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.videoWithAdBannerItemButtons {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}