.main {
  margin: 0 auto;
  /* width: 98%; */
  /* width: 95%; */
  /* max-width: 40rem; */
  /* height: 100vh; */
  /* width: 98%; */
  min-height: 100vh;
  width: 100%;
}

.AppStretch {
  /* min-height: 100vh; */
}

.AppContainer {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}